import React, { Component } from "react";
import app from "../app"

export class WebMenu extends React.Component
{
	onClick()
	{
		console.log("onClick()");
		app.showWebMenu(false);
	}

	onClickDarkMode()
	{
		console.log("onClick()");
		app.toggleTheme();
		app.showWebMenu(false);
	}

	render() {
		console.log("render menu");
		return(
			<div
				id="menu"
			>

{/*				<a
					href="camps.html"
					className="menu-link"
					onClick={(e) => this.onClick(e)}
				>
					<div className="menu-item">Camps</div>
				</a>*/}

				<a
					href="schools.html"
					className="menu-link"
					onClick={(e) => this.onClick(e)}
				>
					<div className="menu-item">Schools</div>
				</a>

				<a
					href="schools.html#pe"
					className="menu-link"
					onClick={(e) => this.onClick(e)}
				>
					<div className="menu-item menu-sub-item">Physical Education</div>
				</a>

				<a
					href="schools.html#am"
					className="menu-link"
					onClick={(e) => this.onClick(e)}
				>
					<div className="menu-item menu-sub-item">Athletic Management</div>
				</a>

				<a
					className="menu-link"
					href="photo.html"
					onClick={(e) => this.onClick(e)}
				>
					<div className="menu-item menu-sub-item">Team Pictures</div>
				</a>

				<a
					href="distance.html"
					className="menu-link"
					onClick={(e) => this.onClick(e)}
				>
					<div className="menu-item">Distance Learning</div>
				</a>

				<a
					href="camps.html"
					className="menu-link"
				>
					<div className="menu-item">Camps</div>
				</a>
				
				<a
					href="contact.html"
					className="menu-link"
				>
					<div className="menu-item">Contact</div>
				</a>


			</div>
		)
	}
}

export default WebMenu;

import React, { Component } from "react";
import app from "../app"

export class BackButton extends React.Component
{
	onClick()
	{
		console.log("onClick()");
		app.showWebMenu();
	}
	render() {
		console.log("render back button");
		return(<div
			id="back-button"
			className="icon-back top-button"
			onClick={(e) => this.onClick(e)}
		>
		</div>)
	}
}

export default BackButton;

import React, { Component } from "react";
import app from "../app"
// import Service from "../service"
// import config from '../config'
// import LocalStore from '../localstore'
// import PictureService from '../pictureservice'


export class WebMenuButton extends React.Component
{
	onClick()
	{
		console.log("onClick()");
		app.menuButtonClick();
	}
	render() {
		console.log("render menu button");
		return(<div
			id="menu-button"
			className="icon-menu top-button"
			onClick={(e) => this.onClick(e)}
		>
		</div>)
	}
}

export default WebMenuButton;

import React, { Component } from "react";
import app from "../app"
// import Service from "../service"
// import config from '../config'
// import LocalStore from '../localstore'
// import PictureService from '../pictureservice'


export class WebTopBar extends React.Component
{
	onClick()
	{
		//console.log("onClick()");
		history.back()
		//app.toggleTheme();
	}
	render() {
		return(
			<div id="top-bar">
				<a
					className="top-logo-link"
					href="index.html"
				>
					<div id="logo-static"></div>
				</a>
				<div
					id="back-button"
					className="top-button"
					onClick={(e) => this.onClick(e)}
				></div>
			</div>
		)
	}
}

export default WebTopBar;

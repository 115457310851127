// their code
import React from 'react';
import ReactDOM from 'react-dom';

import WebMenu from './components/WebMenu';
import WebMenuButton from './components/WebMenuButton';
import BackButton from './components/BackButton';
import WebTopBar from './components/WebTopBar';

var debug = true;
var modules = [];
var screenMode = "?";
var menuOpen = false;

function $(id) { return document.getElementById( id ) };


var app = {

	startApp: function() {
		debug && console.log("startApp()");
		var e = $('menu-container');
		if (!e) {
			return util.wait(100)
			.then( () => {
				//console.log("try again to register " + this.name);
				this.startApp();
			})
		}
		e = $("main-content");
		e.setAttribute("onclick", 'console.log("sup yo")')
		e.setAttribute(
			'onclick',
			'window.dispatchEvent(new Event("clickMain"))'
		)

		this.makeTopBar();
		this.makeMenu();
		this.sizePage();
	},

	makeTopBar: function() {
		debug && console.log("makeTopBar()");
		const e = $('top-bar-container');
		if (e) ReactDOM.render(<WebTopBar />, e);
	},

	makeMenu: function() {
		debug && console.log("makeMenu()");
		ReactDOM.render(
			<WebMenu />,
			$('menu-container')
		);
		ReactDOM.render(
			<WebMenuButton />,
			$('menu-button-container')
		);
	},

	// registerAppPage: function(module) {
	// 	if (!module.name ||
	// 		!module.renderElement ||
	// 		!module.initialize
	// 	){
	// 		var s = (module.name) ? module.name : "?";
	// 		console.error("improper module " + s);
	// 		console.dir(module);
	// 		return null;
	// 	}
	// 	try {
	// 		this.modules[module.name] = module;
	// 		//if (debug) console.log("registered full page module: "+module.name);
	// 	} catch (e) {
	// 		// disabled app style page components
	// 	}
	// },

	sizePage: function() {
		debug && console.log("sizePage()");
		const main = $("main-content");
		const logo = $("logo-container");
		const menu = $("menu");
		const menuButton = $("menu-button");

		if (!main || !menu) {
			console.log("required elements missing for resize");
			return;
		}

		menuOpen = false;

		//main.style.height = window.innerHeight+"px";
		if (window.innerWidth > 720) {
			console.log("wide screen layout " + window.innerWidth);
			screenMode = "wide";

			menuButton.style.display = "none";

			menu.style.position = "fixed";
			menu.style.top = 0;
			menu.style.left = 0;
			menu.style.width = "20%";
			// menu.style.height = '100%';
			menu.style.padding = "42pt 0pt 0pt 0pt";
			main.style.width = "80%";
			main.style.margin = "0 0 0 20%";
			if (logo) logo.style.width = "80%"

			var ultra = 1040;
			var ultraMenu = 240;
			var ultraMain = 800;
			if (window.innerWidth > ultra)
			{
				screenMode = "wide2";
				console.log("ULTRA wide screen layout " + window.innerWidth);
				main.style.width = ultraMain+"px";
				if (logo) logo.style.width = ultraMain+"px";
				menu.style.width = ultraMenu+"px";

				var m = (window.innerWidth - ultraMenu - ultraMain)/2;
				m += ultraMenu;
				main.style.margin = "0 0 0 " +m+"px" ;
			}

		} else {
			screenMode = "tall";

			menuButton.style.display = "block";

			main.style.width = "auto";
			main.style.margin = "auto";

			menu.style.width = "auto";
			menu.style.height = 'auto';
			menu.style.position = "relative";
			menu.style.padding = "0pt 0pt 0pt 0pt";

			if (logo) logo.style.width = "100%"
		}
	},

	menuButtonClick: function() {
		if (screenMode === "tall") {
			if (menuOpen) {
				this.showWebMenu(false);
			} else {
				this.showWebMenu(true);
			}
		}
	},

	showWebMenu: function(b) {
		if (!b) {
			menuOpen = false;
			this.sizePage();
		} else {
			menuOpen = true;
			const menu = $("menu");
			menu.style.position = "fixed";
			menu.style.top = 0;
			menu.style.right = 0;
			menu.style.left = 'auto';
			menu.style.width = "240px";
			menu.style.padding = "42pt 0pt 0pt 0pt";

			// scroll the page past the logo movement sequence
			const logo = $('logo-container');
			if (logo) {
				const y = document.documentElement.scrollTop;

				const scrollMax = 250; // todo shared const

				// avoid logo on top of menu
				if (y < scrollMax) {
					window.scrollTo(0, scrollMax);
				} else {
	
				}
			}
		}
	},

	goLinkedin: function () {
		console.log("goLinkedin()");
		window.open(
			'https://www.linkedin.com/in/trevor-duckworth-m-s-05aa2a4/',
			'_blank'
		);
	},

	showPhoneNumber: function () {
		console.log("showPhoneNumber()");
		var elm = document.getElementById("phone-number-container");
		console.log(elm.style.display)
		if (elm.style.display === "block"){
			elm.style.display = "none";
		} else {
			elm.style.display = "block";
		}
	},

	gotoPayments: function() {
		console.log("gotoPayments()");
		window.location.href = "https://pay.benchmarkathletics.com:3000";
	},

	makeSecure: function() {
		console.log("makeSecure()");
		if (location.protocol !== 'https:') {
			console.warn("using secure connection");
			location.replace(`https:${location.href.substring(location.protocol.length)}`);
		}
	}
};


document.addEventListener("DOMContentLoaded", function() {
	debug && console.log("page ready");
	app.startApp();
});

window.addEventListener('resize', function() {
	debug && console.log("page resize");
	app.sizePage();
});

window.addEventListener('clickMain', function (e) {
	//console.log('clickMain');
	app.showWebMenu(false);
});


export default app;
